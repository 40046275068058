import Icon from 'shopper/components/Icon';

import placeholder from 'lib/placeholder';

import { isInDateRange } from './date';

import LINKS from 'constants/links';

const INVALID_CATEGORY_SLUGS = ['bebidas'];

export const getAdCssBackgroundColorProp = (
  [bgColor1, bgColor2] = [null, null]
) =>
  !bgColor2
    ? bgColor1
    : `linear-gradient(45deg, ${bgColor1} 0% 50%, ${bgColor2} 50% 100%)`;

export const isOfferCategorySlugValidToActivateGoogleAd = (slug) =>
  !INVALID_CATEGORY_SLUGS.some((invalidSlug) => invalidSlug === slug);

export const getSponsoredStoreAd = () => {
  if (isInDateRange('2024-11-11', '2024-11-18')) {
    return {
      href: LINKS.ALIEXPRESS_STORE,
      text: placeholder('actions.seeAliexpressSponsored'),
      gaEventAction: 'topbar_aliexpress_tab',
      testSelectorAttr: 'aliexpress-tab',
      icon: <Icon name="aliexpress" />,
    };
  }

  if (isInDateRange('2024-11-19', '2024-11-24')) {
    return {
      href: LINKS.AMAZON_STORE,
      text: placeholder('actions.seeAmazonSponsored'),
      gaEventAction: 'topbar_amazon_tab',
      testSelectorAttr: 'amazon-tab',
      icon: <Icon name="amazon" />,
    };
  }

  if (isInDateRange('2024-11-25', '2024-12-08')) {
    return {
      href: LINKS.SAMSUNG_STORE,
      text: placeholder('actions.seeSamsungSponsored'),
      gaEventAction: 'topbar_samsung_tab',
      testSelectorAttr: 'samsung-tab',
      icon: <Icon name="samsung-blue" />,
    };
  }

  return null;
};
